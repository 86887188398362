import {Font} from "./../app/font";
import {Motif} from "./../app/motif";

export const variables = {
  fonts: [
    new Font(1, 'Maven Pro', 'Maven_Pro'),
    new Font(2, 'MarketingScript', 'MarketingScript'),
    new Font(3, 'Matchbook', 'Matchbook'),
    new Font(4, 'Lintsec', 'Lintsec'),
    new Font(5, 'Inconsolata', 'Inconsolata'),
    new Font(6, 'England Hand DB', 'England_Hand'),
    new Font(7, 'Cicle', 'Cicle'),
    new Font(8, 'ChunkFive', 'ChunkFive'),
    new Font(9, 'CAC Champagne', 'CAC_Champagne'),
    new Font(10, 'Boston Traffic', 'Boston_Traffic'),
    new Font(11, 'Yanone Kaffeesatz', 'Yanone_Kaffeesatz'),
    new Font(12, 'PUSAB', 'PUSAB'),
    new Font(13, 'Pacifico', 'Pacifico'),
    new Font(14, 'Old Standard TT', 'Old_Standard_TT'),
    new Font(15, 'Note this', 'Note_this'),
    new Font(16, 'Lobster Two', 'Lobster_Two'),
    new Font(17, 'Blackout', 'Blackout')
  ],

  motifs: [
    new Motif(1, 'Motiv', '0_xmas_01_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_02_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_03_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_04_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_05_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_06_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_08_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_09_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_11_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_12_thumb.png'),
    new Motif(1, 'Motiv', '0_xmas_15_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_00_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_01_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_02_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_03_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_04_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_05_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_07_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_06_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_19_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_08_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_09_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_10_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_12_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_13_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_15_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_16_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_17_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_18_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_20_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_14_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_21_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_22_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_23_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_24_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_25_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_26_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_27_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_28_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_29_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_11_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_30_thumb.png'),
    new Motif(1, 'Motiv', 'dinge_31_thumb.png'),
    new Motif(1, 'Motiv', 'misc_01_thumb.png'),
    new Motif(1, 'Motiv', 'misc_02_thumb.png'),
    new Motif(1, 'Motiv', 'misc_03_thumb.png'),
    new Motif(1, 'Motiv', 'misc_04_thumb.png'),
    new Motif(1, 'Motiv', 'misc_05_thumb.png'),
    new Motif(1, 'Motiv', 'misc_06_thumb.png'),
    new Motif(1, 'Motiv', 'misc_07_thumb.png'),
    new Motif(1, 'Motiv', 'misc_08_thumb.png'),
    new Motif(1, 'Motiv', 'misc_09_thumb.png'),
    new Motif(1, 'Motiv', 'misc_10_thumb.png'),
    new Motif(1, 'Motiv', 'misc_11_thumb.png'),
    new Motif(1, 'Motiv', 'misc_12_thumb.png'),
    new Motif(1, 'Motiv', 'misc_13_thumb.png'),
    new Motif(1, 'Motiv', 'misc_14_thumb.png'),
    new Motif(1, 'Motiv', 'misc_15_thumb.png'),
    new Motif(1, 'Motiv', 'misc_16_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_01_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_02_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_03_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_04_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_05_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_06_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_07_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_08_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_09_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_10_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_11_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_12_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_13_thumb.png'),
    new Motif(1, 'Motiv', 'dekorativ_14_thumb.png'),
    new Motif(1, 'Motiv', 'blume_01_thumb.png'),
    new Motif(1, 'Motiv', 'blume_02_thumb.png'),
    new Motif(1, 'Motiv', 'blume_03_thumb.png'),
    new Motif(1, 'Motiv', 'blume_04_thumb.png'),
    new Motif(1, 'Motiv', 'blume_05_thumb.png'),
    new Motif(1, 'Motiv', 'blume_06_thumb.png'),
    new Motif(1, 'Motiv', 'stern_01_thumb.png'),
    new Motif(1, 'Motiv', 'stern_02_thumb.png'),
    new Motif(1, 'Motiv', 'stern_03_thumb.png'),
    new Motif(1, 'Motiv', 'stern_04_thumb.png'),
    new Motif(1, 'Motiv', 'stern_05_thumb.png'),
    new Motif(1, 'Motiv', 'stern_06_thumb.png'),
    new Motif(1, 'Motiv', 'stern_07_thumb.png'),
    new Motif(1, 'Motiv', 'stern_08_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_01_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_02_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_03_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_04_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_05_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_06_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_07_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_08_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_09_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_10_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_11_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_12_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_13_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_14_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_15_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_16_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_17_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_18_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_19_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_20_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_21_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_22_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_23_thumb.png'),
    new Motif(1, 'Motiv', 'sternzeichen_24_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_01_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_02_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_03_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_04_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_05_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_06_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_07_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_08_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_09_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_10_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_11_thumb.png'),
    new Motif(1, 'Motiv', 'rahmen_12_thumb.png'),


  ],

  products: [
    {
      id: 1,
      setup: 8,
      fixPrice: 0,
      views: [
        {
          id: 0,
          productId: 1,
          name: 'Vorderseite',
          image: 'https://dev.signuu.com/konfigurator/service/var/shopProxy/media/catalog/product//g/r/gravur-lasergravur-cent_gravurflaeche_1_15x15mm_42_44_406_406_rgb_0_0_0__1.jpg',
          originalWidth: 15,
          originalHeight: 15,
          xPosition: 42,
          YPosition: 44,
          width: 406,
          height: 406,
          engravingColor: 'rgb(0,0,0)',
          material: 'A',
          extraCost: 2,
          circle: true,
          fixPrice: 0
        },
        {
          id: 1,
          productId: 1,
          name: 'Rückseite',
          image: 'https://dev.signuu.com/konfigurator/service/var/shopProxy/media/catalog/product//g/r/gravur-lasergravur-cent_gravurflaeche_2_15x15mm_42_44_406_406_rgb_0_0_0__1.jpg',
          originalWidth: 15,
          originalHeight: 15,
          xPosition: 42,
          YPosition: 44,
          width: 406,
          height: 406,
          engravingColor: 'rgb(0,0,0)',
          material: 'A',
          extraCost: 2,
          circle: true,
          fixPrice: 0
        }]
    },
    {
      id: 2,
      setup: 8,
      fixPrice: 0,
      views: [
        {
          id: 0,
          productId: 2,
          name: 'Salzstreuer',
          image: 'https://dev.signuu.com/konfigurator/service/var/shopProxy/media/catalog/product//3/e/3ermenage_gf1_15x56mm_204_54_80_298_rgb_0_0_0_.jpg',
          originalWidth: 15,
          originalHeight: 56,
          xPosition: 204,
          yPosition: 54,
          width: 80,
          height: 298,
          engravingColor: 'rgb(255,255,3)',
          material: 'A',
          extraCost: 2,
          circle: false,
          fixPrice: 0
        },
        {
          id: 1,
          productId: 2,
          name: 'Pfefferstreuer',
          image: 'https://dev.signuu.com/konfigurator/service/var/shopProxy/media/catalog/product//3/e/3ermenage_gf1_15x56mm_204_54_80_298_rgb_0_0_0_.jpg',
          originalWidth: 15,
          originalHeight: 56,
          xPosition: 204,
          yPosition: 54,
          width: 80,
          height: 298,
          engravingColor: 'rgb(255,255,255)',
          material: 'A',
          extraCost: 2,
          circle: false,
          fixPrice: 0
        },
        {
          id: 2,
          productId: 2,
          name: 'Dose Front',
          image: 'https://dev.signuu.com/konfigurator/service/var/shopProxy/media/catalog/product//3/e/3ermenage_gf4_15x39mm_206_76_89_230_rgb_0_0_0_.jpg',
          originalWidth: 15,
          originalHeight: 39,
          xPosition: 206,
          yPosition: 76,
          width: 89,
          height: 230,
          engravingColor: '#95c11e',
          material: 'A',
          extraCost: 2,
          circle: false,
          fixPrice: 0
        },
        {
          id: 3,
          productId: 2,
          name: 'Deckel Dose',
          image: 'https://dev.signuu.com/konfigurator/service/var/shopProxy/media/catalog/product//3/e/3ermenage_gf3_40mm_145_145_211_211_rgb_0_0_0_.jpg',
          originalWidth: 40,
          originalHeight: 40,
          xPosition: 145,
          yPosition: 145,
          width: 211,
          height: 211,
          engravingColor: 'rgb(0,0,0)',
          material: 'A',
          extraCost: 2,
          circle: true,
          fixPrice: 0
        }
      ]
    }
  ],

  uploadedImages: [
  ]
  /*
  uploadedImages: [
    {
      name: 'upload 1',
      src: '../assets/images/WP_20150603_008.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/CIMG3168.JPG',
    },
    {
      name: 'upload 1',
      src: '../assets/images/WP_20140615_004.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/V__DC41.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/WP_20150603_008.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/WP_20150603_008.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/CIMG3168.JPG',
    },
    {
      name: 'upload 1',
      src: '../assets/images/WP_20140615_004.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/V__DC41.jpg',
    },
    {
      name: 'upload 1',
      src: '../assets/images/WP_20150603_008.jpg',
    }
  ]*/
};
