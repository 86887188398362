import { variables } from './../environments/variables'
import {Component, Directive, Input} from '@angular/core';
//import {Font} from './font';
//import {Motif} from './motif';
import {SignuuService} from './signuu.service';
// import {AngularTooltips} from 'angular-tooltips';
/* import { Observable } from 'rxjs'; */
import { FileUploader } from 'ng2-file-upload';

import 'fabric';
declare const fabric: any;

declare global {
  interface Window {webkitURL: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'app';
  tab = 0;
  private checkAgreements: boolean;

  text = 'Mein Gravurtext';
  fontFamily = 'Maven Pro';
  private textString: string;
  public showPopup: boolean;

  public sessionID: string;
  public session: any = {
        identifier: null,
        preview: '',
        svg: ''
    };

  public deviceWidth: number;
  public breakpoint: number;
  public isTouchDevice: boolean;
  public isSmallDevice: boolean;
  public deviceOrientation: string;

  public isLoading: boolean;
  public isUploadLoading: boolean;
  private laserProductId;
  public product: any;
  private readonly route: string;

  public productViews: any;
  private activeProductViewNr: number;
  public activeProductView: any;

  private canvasConfig: any;
  private canvasConfig2: any;
  private activeCanvas: any;

  public fonts: any;
  public motifs: any;
  public products: any;
  public uploadedImages: any;

  // file upload
  public uploader: FileUploader;
  public hasBaseDropZoneOver:boolean = false;
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public canvas: any;
  private canvasProps: any = {
    width: 500,
    height: 500,
    selectionColor: 'rgba(149,193,31,0.2)',
    selectionBorderColor: 'rgba(149,193,31,0.2)',
    rotationCursor: 'url(http://127.0.0.1:4200/assets/images/cursor-rotate.cur) 15 15, crosshair',
    engravingColor: '#000000',
    preserveObjectStacking: true
  };

  private props: any = {
    left: 0,
    top: 0,
    flipX: false,
    flipY: false,
    fill: 'rgb(255,255,255)',
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    charSpacing: 1,
    fontFamily: 'Maven Pro',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.0,
    // styles: {},
    textAlign: 'left',
    threshold: 0.5,
    invert: false
  };

  constructor(public _signuuService: SignuuService) {
    let loc = location.pathname.substr(1);
    this.route = loc ? loc : '402'; // fallback to cent if no product is set
    this.product = {};
    this.product.config = [];
    this.isLoading = true;

    // load variables
    this.fonts = variables.fonts;
    this.motifs = variables.motifs;
    this.products = variables.products;
    this.uploadedImages = variables.uploadedImages;
  }

  ngOnInit() {

    const self = this;
    this.breakpoint = 960;

    document.addEventListener('DOMContentLoaded', function () {

      let initialScale = 1.0;
      let deviceWidth = window.screen.width;
      let orientation = window.orientation;
      let contentWidth = 500;
      if (deviceWidth < contentWidth) {
        initialScale = deviceWidth / contentWidth;
      }
      let meta = document.createElement('meta');
      meta.setAttribute('name', 'viewport');
      meta.setAttribute('content', 'width=' + contentWidth + ', initial-scale=' + initialScale + ', user-scalable=no, user-scalable=0');
      document.head.appendChild(meta);

      self.deviceWidth = deviceWidth;
      let e: any;
      e = window;
      let a = 'inner';
      if (!('innerWidth' in window)){
        a = 'client';
        e = document.documentElement || document.body;
      }
      let view_width = e[ a+'Width' ];
      let view_height = e[ a+'Height' ];

      if ( orientation === 90) {
        self.deviceOrientation = 'landscape';
      } else {
        self.deviceOrientation = 'portrait';
      }
      if (self.deviceWidth < self.breakpoint && self.deviceOrientation === 'portrait') { self.isSmallDevice = true; }
      //alert(self.isSmallDevice);
      //alert(self.deviceWidth);
      //alert(window.orientation);
      //alert(view_width);
      //alert(view_height);
      //alert(self.isSmallDevice);
    });

    window.addEventListener(
      'touchstart',
       function onFirstTouch() {
         // we could use a class
         document.body.classList.add('touch');

         // or set some global variable
         self.isTouchDevice = true;

         self.prepareForTouch();

         // we only need to know once that a human touched the screen, so we can stop listening now
         window.removeEventListener('touchstart', onFirstTouch, false);
       },
      false
    );

    this.getProductId();
  }

  getProductId() {
    this._signuuService.getLaserProductId(this.route).subscribe(
      data => {
        this.laserProductId = data['productid'][0];
        this.getFullProduct();
      },
      err => console.log(err),
      () => console.log('done loading product id')
    );
  }

  getFullProduct() {
    this._signuuService.getProductsAndPictures(this.laserProductId).subscribe(
      data => {
        this.product = data[0];
        this.isLoading = false;
        this.getProductPrice();
        this.loadConfigurator();
      },
      err => console.log(err),
      //() => console.log('error loading full product')
    );
  }

  getProductPriceConfig() {
    let shapes = [];
    for (let i = 0; i < this.canvas.getObjects().length; i++) {
      let item = this.canvas.getObjects()[i];
      if (item.selectable) {
        shapes.push({
          "x": item.left,
          "y": item.top,
          "width": item.width,
          "height": item.height,
          "scale":{
            "x": item.scaleX,
            "y": item.scaleY
          },
          "rotation": item.angle
        });
      }
    }

    let config = [{
      "view": this.activeProductView.id,
      "shapes": shapes
    }]

    // console.log(this.activeProductView.id)

    this.product.config[this.activeProductView.id];
    this.product.config[this.activeProductView.id] = config;
    this.product.config = config;
    //console.log(config);

    return config;

  }

  getProductPrice() {
    let config = [];
    if(typeof this.canvas !== 'undefined' && typeof this.canvas.getObjects() !== 'undefined')
    {
      config = this.getProductPriceConfig();
    }

    this.product.config = config;

    this._signuuService.getProductPrice(this.laserProductId, config).subscribe(
      data => {
        this.product.price = data['produktpreis'].price;
        this.product.currency = data['produktpreis'].currency;
        this.product.tax = data['produktpreis'].tax;
      },
      err => console.log(err),
      //() => console.log('error price not available')
    );
  }

  loadConfigurator() {

    // const self = this;
    // this.session.identifier =

    // get session
    this.createSession();

    this.tab = null;
    this.showPopup = false;
    this.textString = 'Dein Gravurtext';
    this.checkAgreements = false;

    // set up product view
    this.productViews = this.product.views;
    this.activeProductViewNr = 0;
    this.activeProductView = this.productViews[this.activeProductViewNr];

    this.props.fill =  this.activeProductView.engravingColor;

    this.canvasConfig = [[], [], []];

    // console.log('/// ACTIVE VIEW ///');
    // console.log(this.activeProductView);

     // setup front side canvas
    this.canvas = new fabric.Canvas('canvas', this.canvasProps);
    fabric.textureSize = 4096;

    this.createClipPath(this.canvas, this.activeProductView);
    this.drawCenteringLines(this.canvas, this.activeProductView);
    this.canvas.controlsAboveOverlay = true;
    this.canvas.renderAll();

    // register event handler
    this.canvas.on({
      'object:added' : (e) => {
        this.getProductPrice()
      },
      'object:modified' : (e) => {
        this.getProductPrice()
      },
      'object:removed' : (e) => {
        this.getProductPrice()
      }
    });

    fabric.Object.prototype.set({
      centeredScaling: true,
      transparentCorners: false,
      borderColor: 'rgba(149,193,31,0.75)',
      cornerColor: 'rgba(149,193,31,0.75)',
      cornerStrokeColor: 'rgba(149,193,31,0.75)',
      cornerSize: 10,
      editingBorderColor: 'rgba(149,193,31,0.25)',
      selectionColor: 'rgba(149,193,31,0.25)',
      snapAngle: 90,
      snapThreshold: 5,
      filterable: false
    });
    fabric.Object.prototype.setControlsVisibility({
      ml: false,
      mt: false,
      mr: false,
      mb: false
    })

    // set up canvas 2d filter Backend
    /*
    const canvas2dBackend = new fabric.Canvas2dFilterBackend();
    fabric.filterBackend = fabric.initFilterBackend();
    fabric.filterBackend = canvas2dBackend;
    */

    // set up canvas filter
    fabric.Image.filters.EngraveReady = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
      /**
       * Filter type
       * @param {String} type
       * @default
       */
      type: 'EngraveReady',

      /**
       * Fragment source for the EngraveReady program
       */

      fragmentSource: 'precision highp float;\n' +
      'uniform sampler2D uTexture;\n' +
      'uniform float uThreshold;\n' +
      'uniform vec4 uColor;\n' +
      'varying vec2 vTexCoord;\n' +
      'void main() {\n' +
      'vec4 color = texture2D(uTexture, vTexCoord);\n' +
      'gl_FragColor = color;\n' +
      'if (0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b <= uThreshold) {\n' +
      'gl_FragColor.r = uColor.r;\n' +
      'gl_FragColor.g = uColor.g;\n' +
      'gl_FragColor.b = uColor.b;\n' +
      '} else {\n' +
      'gl_FragColor.a = 0.0;\n' +
      '}\n' +
      '}',

      /**
       * Threshold value, from 0 to 1.
       * translated to 0 to 255 for 2d
       * 0.0039215686 is the part of 1 that get translated to 1 in 2d
       * @param {Number} threshold
       * @default
       */
      threshold: 0.5,
      mainParameter: 'threshold',
      engraveColor: '#000000',

      applyTo2d: function(options) {

        let imageData = options.imageData,
          data = imageData.data, i, r, g, b,
          threshold = Math.round(this.threshold * 255),
          color = new fabric.Color(this.engraveColor).getSource();
        console.log(data.length);

        // Pixel des Bildes anhand des Schwellenwertes manipulieren
        for (i = 0; i < data.length; i += 4) {
          r = data[i];
          g = data[i + 1];
          b = data[i + 2];

          if (0.2126 * r + 0.7152 * g + 0.0722 * b <= threshold) {
            data[i] = color[0];
            data[i + 1] = color[1];
            data[i + 2] = color[2];
          } else {
            data[i + 3] = 0;
          }
        }
      },
      /**
       * Return WebGL uniform locations for this filter's shader.
       *
       * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
       * @param {WebGLShaderProgram} program This filter's compiled shader program.
       */
      getUniformLocations: function(gl, program) {
        return {
          uColor: gl.getUniformLocation(program, 'uColor'),
          uThreshold: gl.getUniformLocation(program, 'uThreshold'),
        };
      },

      /**
       * Send data from this filter to its shader program's uniforms.
       *
       * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
       * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
       */
      sendUniformData: function(gl, uniformLocations) {
        let source = new fabric.Color(this.engraveColor).getSource(),
          color = [
            0 + source[0] / 255,
            0 + source[1] / 255,
            0 + source[2] / 255,
            1
          ];
        gl.uniform4fv(uniformLocations.uColor, color);
        gl.uniform1f(uniformLocations.uThreshold, this.threshold);
      },
      /**
       * EngraveReady filter isNeutralState implementation
       * The filter is never neutral
       * on the image
       **/
      isNeutralState: function() {
        return false;
      }
    });
    fabric.Image.filters.EngraveReady.fromObject = fabric.Image.filters.BaseFilter.fromObject;

    fabric.Image.filters.EngraveInvert = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
      /**
       * Filter type
       * @param {String} type
       * @default
       */
      type: 'EngraveInvert',

      /**
       * Fragment source for the EngraveReady program
       */

      fragmentSource: 'precision highp float;\n' +
      'uniform sampler2D uTexture;\n' +
      'varying vec2 vTexCoord;\n' +
      'uniform vec4 uColor;\n' +
      'void main() {\n' +
      'vec4 color = texture2D(uTexture, vTexCoord);\n' +
      'gl_FragColor = color;\n' +
      'if (gl_FragColor.a == 0.0) {\n' +
      'gl_FragColor.r = uColor.r;\n' +
      'gl_FragColor.g = uColor.g;\n' +
      'gl_FragColor.b = uColor.b;\n' +
      'gl_FragColor.a = 1.0;\n' +
      '} else {\n' +
      'gl_FragColor.a = 0.0;\n' +
      '}\n' +
      '}',

      /**
       * Filter invert. if false, does nothing
       * @param {Boolean} invert
       * @default
       */
      invert: true,
      engraveColor: '#000000',

      mainParameter: 'invert',

      applyTo2d: function(options) {
        let imageData = options.imageData,
          data = imageData.data, i,
          len = data.length,
          color = new fabric.Color(this.engraveColor).getSource();

        for (i = 0; i < len; i += 4) {

          if (data[i + 3] === 0) {
            data[i] = color[0];;
            data[i + 1] = color[1];;
            data[i + 2] = color[2];;
            data[i + 3] = 255;
          } else {
            data[i + 3] = 0;
          }
        }
      },

      /**
       * Return WebGL uniform locations for this filter's shader.
       *
       * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
       * @param {WebGLShaderProgram} program This filter's compiled shader program.
       */
      getUniformLocations: function(gl, program) {
        return {
          uColor: gl.getUniformLocation(program, 'uColor')
        };
      },

      /**
       * Send data from this filter to its shader program's uniforms.
       *
       * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
       * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
       */
      sendUniformData: function(gl, uniformLocations) {
        let source = new fabric.Color(this.engraveColor).getSource(),
          color = [
            0 + source[0] / 255,
            0 + source[1] / 255,
            0 + source[2] / 255,
            1
          ];
        gl.uniform4fv(uniformLocations.uColor, color);
      },

      /**
       * Invert filter isNeutralState implementation
       * Used only in image applyFilters to discard filters that will not have an effect
       * on the image
       * @param {Object} options
       **/
      isNeutralState: function() {
        return !this.invert;
      }
    });
    fabric.Image.filters.EngraveInvert.fromObject = fabric.Image.filters.BaseFilter.fromObject;

    //this.loadMotives();


  }

  /*
  loadMotives():void {

    let self = this;

    this._signuuService.getMotives().subscribe(
      // the first argument is a function which runs on success
      data => {
        console.log('MOTIVES:');
        console.log(data);
        //self.session.id = data['hydra:member'][0]['id'];
      },
      // the second argument is a function which runs on error
      err => console.log(err),
      // the third argument is a function which runs on completion
      // () => console.log('done loading session')
    );

  }
  */


  /**********************/
  /* SESSION FUNCTIONS */
  /*********************/

  guid(): string {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
  }

  getSession() {

    let self = this;

    this._signuuService.getSession(this.session.identifier).subscribe(
      // the first argument is a function which runs on success
      data => {
          self.session.id = data['hydra:member'][0]['id'];
        },
      // the second argument is a function which runs on error
      err => console.log(err),
      // the third argument is a function which runs on completion
      // () => console.log('done loading session')
    );
  }

  createSession() {

    let self = this;
    this.session.identifier = location.hash.replace('#', '');

    if(this.session.identifier !== null && this.session.identifier !== '') {
      // session = {identifier: this.guid(), preview: ''};
      //console.log(this.session)
      self.updateUploadedImages();
      self.getSession();

      self.uploader = new FileUploader({
        url: 'https://www.signuu.com/konfigurator/client/doajaxfileuploadV2.php?session=' + this.session.identifier
      });
/*
      self.uploader.onCompleteAll = function() {
        console.log(AppComponent.session)
        console.log(this.session)
        console.log('onCompleteAll2');
        // let identifier = AppComponent.session.identifier;
        self.updateUploadedImages;
      }*/
      return;
    }
    console.log('create new session');

    self.session = {identifier: this.guid(), preview: '', svg: ''};

    this._signuuService.createSession(self.session).subscribe(
      data => {
        self.session.id = data['id'];
        window.history.pushState( { }, null, this.route + '#' + data['identifier']);
        return true;
      },
      error => {
        console.log("Error saving session!");
        console.log(error);
        return false;
        // return Observable.throw(erisSelectedror);
      }
    );
  }

  updateSession(session) {

    let self = this;

    if(!session)
      session = self.session;

    this._signuuService.updateSession(session).subscribe(
      data => {
        // refresh the list
        //console.log(data);
        console.log('session updated');
        this.showPopup = true;
        this.canvas.clear();
        // this.getSession();
        return true;
      },
      error => {
        console.log("Error saving session!");
        // return Observable.throw(error);
      }
    );
  }

  getProduct() {

    this._signuuService.getLaserProductId(this.route).subscribe((res)=>{
      this.laserProductId = res['productid'][0];
      return this._signuuService.getProductsAndPictures(this.route).subscribe(
        data => {
          // refresh the list
          // console.log(data);
          // console.log('product loaded');
          this.product = data;
          return true;
        },
        error => {
          console.log("Error saving food!");
          // return Observable.throw(error);
        }
      );
    });
  }
  ////


  /*******************/
  /* MENU FUNCTIONS */
  /*****************/

  //  Tabs
  selectTab(setTab): void {
    this.tab = setTab;
  }

  isSelected(checkTab): boolean {
    return this.tab === checkTab;
  }

  closeTabIfIsSmallDevice(): void {
    if (this.isSmallDevice) {
      this.tab = 0;
    }
  }

  //  Views
  selectView(setView): void {
    let self = this;
    //console.log('+++');
    //console.log(this.canvas.getObjects());
    //console.log('+++');
    // save canvas config of actual view
    this.canvasConfig[this.activeProductViewNr] = this.canvas.toJSON();
    //console.log(this.canvas.getObjects());
    //console.log('----------------');
    // switch to new view
    this.activeProductViewNr = setView;

    this.activeProductView = this.productViews[this.activeProductViewNr];
    // load canvas config of new view
    this.canvas.loadFromJSON(this.canvasConfig[this.activeProductViewNr], function() {
        // set clipPath (Engraving Area)
        self.createClipPath(self.canvas, self.activeProductView);
        self.colorObjects(self.canvas, self.props, self.props.fill);
        self.canvas.renderAll();
    });
    // set engraving color of new view
    this.props.fill =  this.activeProductView.engravingColor;
  }

  viewIsSelected(checkView): boolean {
    return this.activeProductViewNr === checkView;
  }

  /*********************/
  /* OBJECT FUNCTIONS */
  /*******************/

  showDefaultTools(): object {
    if (this.textInEditing(null)) {
        return null;
      } else {
        return this.canvas.getActiveObject();
      }
  }

  closeAllTools(): void {
    this.canvas.discardActiveObject();
  }

  getActiveStyle(styleName, object): object {
    object = object || this.canvas.getActiveObject();
    if (!object) return {};

    return (object.getSelectionStyles && object.isEditing)
      ? (object.getSelectionStyles()[styleName] || '')
      : (object[styleName] || '');
  }

  setActiveStyle(styleName, value, object): void {
    object = object || this.canvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      let style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.requestRenderAll();
  }

  setActiveStyle2(styleName, value, shape): void {
    shape = shape || this.canvas.getActiveObject();
    if (!shape) return;

    if (!shape.path) {
      shape.set('fill', value);
    } else if (shape.path) {
      shape.set('stroke', value);
    }
    shape.setCoords();
    this.canvas.requestRenderAll();
  }

  getActiveProp(propName): string {
    const object = this.canvas.getActiveObject();
    if (!object) return '';

    return object[propName] || '';
  }

  setActiveProp(propName, value): void {
    const object = this.canvas.getActiveObject();
    if (!object) return;

    object.set(propName, value).setCoords();
    this.canvas.renderAll();
  }

  sendBackwards(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.sendBackwards(activeObject);
    }
  }

  sendToBack(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      console.log('#####' + activeObject);
      this.canvas.sendToBack(activeObject);
    }
  }

  bringForward(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringForward(activeObject);
    }
  }

  bringToFront(): void {
    let activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringToFront(activeObject);
    }
  }

  getFlipX(): object {
    return this.getActiveStyle('flipX', null);
  }
  setFlipX(value): void {
    this.setActiveStyle('flipX', value, null);
  }

  getFlipY(): object {
    return this.getActiveStyle('flipY', null);
  }
  setFlipY(value): void {
    this.setActiveStyle('flipY', value, null);
  }

  getType(): object {
    return this.getActiveStyle('type', null);
  }
  setType(value): void {
    this.setActiveStyle('type', value, null);
  }

  prepareForTouch(): void {
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderColor: 'rgba(149,193,31,0.25)',
      cornerColor: 'rgba(149,193,31,0.25)',
      cornerStrokeColor: 'rgba(149,193,31,0.25)',
      cornerSize: 25
    });
    if (this.isSmallDevice) {
      fabric.util.object.extend(fabric.IText.prototype, {
        /**
         * standard hander for mouse up, overridable
         * @private
         */
        mouseUpHandler: function (options) {
          this.__isMousedown = false;
          if (!this.editable || this.group ||
            (options.transform && options.transform.actionPerformed) ||
            (options.e.button && options.e.button !== 1)) {
            return;
          }

          if (this.canvas) {
            var currentActive = this.canvas._activeObject;
            if (currentActive && currentActive !== this) {
              // avoid running this logic when there is an active object
              // this because is possible with shift click and fast clicks,
              // to rapidly deselect and reselect this object and trigger an enterEdit
              return;
            }
          }

          if (this.__lastSelected && !this.__corner) {
            this.selected = false;
            this.__lastSelected = false;
            //this.enterEditing(options.e);
            if (this.selectionStart === this.selectionEnd) {
              this.initDelayedCursor(true);
            }
            else {
              this.renderCursorOrSelection();
            }
          }
          else {
            this.selected = true;
          }
        },
        /**
         * Initializes event handlers related to cursor or selection
         */
        initCursorSelectionHandlers: function () {
          this.initMousedownHandler();
          this.initMouseupHandler();
          this.initClicks();
          this.initMouseDblClickHandler();
          this.initTouchLongpressHandler();
        },
        /**
         * Initializes "mousedblClick" event handler
         */
        initMouseDblClickHandler: function () {
          this.on('mousedblclick', this.MouseDblClickHandler);
        },
        MouseDblClickHandler: function (options) {
          this.enterEditing(options.e);
        },
        /**
         * Initializes "touchlongpress" event handler
         */
        initTouchLongpressHandler: function () {
          this.on('touch:longpress', this.TouchLongpressHandler);
        },
        TouchLongpressHandler: function (options) {
          this.enterEditing(options.e);
        },
      });
    }
  }

  /**************************/
  /* TEXT OBJECT FUNCTIONS */
  /************************/

  textInEditing(object): boolean {
    object = object || this.canvas.getActiveObject();
    if (!object) return false;

    return object.isEditing;
  }

  capitalize(str): string {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  }

  getText(): string {
    return this.getActiveProp('text');
  }
  setText(value): void {
    this.setActiveProp('text', value);
  }

  getTextAlign(): string {
    return this.getActiveProp('textAlign');
  }
  setTextAlign(value): void {
    this.setActiveProp('textAlign', value);
  }

  getFontFamily(): void {
    this.props.fontFamily = this.getActiveProp('fontFamily');
  }
  setFontFamily(): void {
    this.setActiveProp('fontFamily', this.props.fontFamily);
    console.log(this.props.fontFamily);
  }

  getFontSize(): void {
    this.props.fontSize = this.getActiveStyle('fontSize', null);
  }
  setFontSize(): void {
    this.setActiveStyle('fontSize', parseInt(this.props.fontSize), null);
  }

  getCharSpacing(): void {
    this.props.charSpacing = this.getActiveStyle('charSpacing', null);
  }
  setCharSpacing(): void {
    this.setActiveStyle('charSpacing', this.props.charSpacing, null);
  }

  getLineHeight(): object {
    return this.props.lineHeight = this.getActiveStyle('lineHeight', null);
  }
  setLineHeight(): void {
    this.setActiveStyle('lineHeight', this.props.lineHeight, null);
  }



  /**********************/
  /* CANVAS FUNCTIONS */
  /*********************/

  addText(): void {

    const text = new fabric.IText(this.textString, this.props);
    this.canvas.add(text);
    this.canvas.setActiveObject(text);
    text.center();
  }

  addSVG(svgSrc): void {
    const self = this;
    const engraving_color = self.props.fill;
    fabric.loadSVGFromURL(svgSrc, function (objects, options) {
      const loadedObject = fabric.util.groupSVGElements(objects, self.props);

      self.canvas.add(loadedObject);
      self.canvas.setActiveObject(loadedObject);
      loadedObject.center();
/*
      if (loadedObject.isSameColor && loadedObject.isSameColor() || !loadedObject.paths) {
        loadedObject.set('fill', engraving_color);
        console.log('1 ' + engraving_color);
      } else if (loadedObject.paths) {
        for (let i = 0; i < loadedObject.paths.length; i++) {
          loadedObject.paths[i].set({fill: engraving_color});
        }
        console.log('2 ' + engraving_color);
      }
      */
      loadedObject.setCoords();
      self.canvas.requestRenderAll();

    });
  }


  addMotif(imageSrc): void  {

    const self = this;
    const margin = 50;
    let scaleFactor = 1;
    let motivThumbURL = 'https://signuu.com/konfigurator/client/img/motive/' + imageSrc;
    let motivURL = motivThumbURL.replace('_thumb','');

    fabric.Image.fromURL(motivURL, function (img) {

      scaleFactor = self.getImageScale(img, margin, null, null);
      img.set(self.props).scale(scaleFactor);
      let color = new fabric.Color(self.props.fill).toHex();
      img.filters[0] = new fabric.Image.filters.BlendColor({color: color, mode: 'add'});
      img.applyFilters();
      self.canvas.add(img);
      self.canvas.setActiveObject(img);
      img.center();
    }, { crossOrigin: 'anonymous' });
  }


  addUpload(imageSrc): void {
    /*
    const self = this;
    const margin = 25;
    let scaleFactor = 1;
    let scaling = 1;
    let resolutionSize = {
      width: 500,
      height: 500
    }

    let temp_canvas = new fabric.Canvas('temp_canvas', {width: 500, height: 500});

    fabric.Image.fromURL(imageSrc, function (img) {
      scaling = self.getImageScale(img, 0, resolutionSize.width, resolutionSize.height);
      img.scale(scaling);
      temp_canvas.add(img);
      fabric.Image.fromURL(temp_canvas.toDataURL(), function (img) {
        scaleFactor = self.getImageScale(img, margin, null, null);
        img.set(self.props).scale(scaleFactor);
        img.set('filterable', true);
        self.props.invert = false;
        self.applyEngravingFilters(img, scaleFactor);
        self.canvas.add(img);
        self.canvas.setActiveObject(img);
        img.center();
      }, { crossOrigin: 'anonymous' });
    }, { crossOrigin: 'anonymous' });
*/
    const self = this;
    const margin = 25;
    let scaleFactor = 1;

    fabric.Image.fromURL(imageSrc, function (img) {
      scaleFactor = self.getImageScale(img, margin, null, null);
      img.set(self.props).scale(scaleFactor);
      img.set('filterable', true);
      self.props.invert = false;
      self.applyEngravingFilters(img, scaleFactor);
      self.canvas.add(img);
      self.canvas.setActiveObject(img);
      img.center();
    }, { crossOrigin: 'anonymous' });
  }


  removeSelected(): void {
    let activeObjects = this.canvas.getActiveObjects();
    this.canvas.discardActiveObject();
    if (activeObjects.length) {
      this.canvas.remove.apply(this.canvas, activeObjects);
    }
  }

  drawCenteringLines(canvas, rect): void {

    let canvasWidth = rect.width,
      canvasHeight = rect.height,
      canvasWidthCenter = rect.xPosition + canvasWidth / 2,
      canvasHeightCenter = rect.yPosition + canvasHeight / 2,
      canvasWidthCenterMap = { },
      canvasHeightCenterMap = { },
      centerLineMargin = 5,
      centerLineOverflow = 25,
      centerLineColor = 'rgba(0,0,0,0.25)',
      centerLineWidth = 1,
      ctx = canvas.getSelectionContext(),
      viewportTransform;

    for (let i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
      canvasWidthCenterMap[Math.round(i)] = true;
    }
    for (let i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
      canvasHeightCenterMap[Math.round(i)] = true;
    }

    function showVerticalCenterLine() {
      showCenterLine(canvasWidthCenter + 0.5, rect.yPosition - centerLineOverflow, canvasWidthCenter + 0.5, canvasHeight + rect.yPosition + centerLineOverflow);
    }

    function showHorizontalCenterLine() {
      showCenterLine(rect.xPosition - centerLineOverflow, canvasHeightCenter + 0.5, rect.xPosition + canvasWidth + centerLineOverflow, canvasHeightCenter + 0.5);
    }

    function showCenterLine(x1, y1, x2, y2) {
      ctx.save();
      ctx.strokeStyle = centerLineColor;
      ctx.lineWidth = centerLineWidth;
      ctx.beginPath();
      ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
      ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
      ctx.stroke();
      ctx.restore();
    }

    let afterRenderActions = [ ],
      isInVerticalCenter,
      isInHorizontalCenter;

    canvas.on('mouse:down', function () {
      viewportTransform = canvas.viewportTransform;
    });

    canvas.on('object:moving', function(e) {
      let object = e.target,
        objectCenter = object.getCenterPoint(),
        transform = canvas._currentTransform;

      if (!transform) return;

      isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap,
        isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;

      if (isInHorizontalCenter || isInVerticalCenter) {
        object.setPositionByOrigin(new fabric.Point((isInVerticalCenter ? canvasWidthCenter : objectCenter.x), (isInHorizontalCenter ? canvasHeightCenter : objectCenter.y)), 'center', 'center');
      }
    });

    canvas.on('before:render', function() {
      canvas.clearContext(canvas.contextTop);
    });

    canvas.on('after:render', function() {
      if (isInVerticalCenter) {
        showVerticalCenterLine();
      }
      if (isInHorizontalCenter) {
        showHorizontalCenterLine();
      }
    });

    canvas.on('mouse:up', function() {
      // clear these values, to stop drawing guidelines once mouse is up
      isInVerticalCenter = isInHorizontalCenter = null;
      canvas.renderAll();
    });
  }

  createClipPath(canvas, view): void {

    const circle = view.circle,
      x = view.xPosition,
      y = view.yPosition,
      width = view.width,
      height =  view.height,
      radius = width / 2,
      offset = 0;

    let clippingObject = new fabric.Object();
    let borderObject = new fabric.Object();

    if (circle) {
      clippingObject = new fabric.Circle({ radius: radius, top: y, left: x });
      borderObject = new fabric.Circle({ radius: radius - offset, top: y, left: x });
    } else {
      clippingObject = new fabric.Rect({ width: width, height: height, top: y, left: x });
      borderObject = new fabric.Rect({ width: width - offset, height: height - offset, top: y, left: x });
    }
    clippingObject.set({
      stroke: 'rgb(255,255,0)',
      strokeWidth: 1,
      fill: 'transparent',
      selectable: false
    });
    borderObject.set({
      // strokeWidth: 2,
      //strokeDashArray: [4, 4],
      stroke: 'rgba(149,193,31,1)',
      fill: 'transparent',
      selectable: false,
      excludeFromExport: true
    });
    canvas.add(borderObject);
    borderObject.sendToBack();
    canvas.clipPath = clippingObject;
    canvas.renderAll();
  }


  /**********************/
  /* IMAGE FUNCTIONS */
  /*********************/

  applyEngravingFilters(img, scale): void {

    let color = new fabric.Color(this.props.fill).toHex();

    //img.filters[0] = new fabric.Image.filters.Resize({scaleX: scale, scaleY: scale});
    img.filters[1] = new fabric.Image.filters.EngraveReady({threshold: 0.5, engraveColor: color});
    img.filters[2] = new fabric.Image.filters.EngraveInvert({invert: false});
    //img.filters[3] = new fabric.Image.filters.BlendColor({color: color, mode: 'add'});
    img.applyFilters();
  }

  applyFilterValue(index, prop, value): void {

    let self = this;
    let obj = self.canvas.getActiveObject();

    if (obj.filters[index]) {
      obj.filters[index][prop] = value;
      obj.applyFilters();
      self.canvas.renderAll();
    }
  }

  getImageScale(image, margin, targetWidth, targetHeight): number {

    let self = this;
    let scale = 1;
    // original size of image
    let width = image.width;
    let height = image.height;
    // size of view - margin
    let viewWidth = targetWidth || self.activeProductView.width;
    viewWidth = viewWidth - margin;
    let viewHeight = targetHeight || self.activeProductView.height;
    viewHeight = viewHeight - margin;
    // if landscape
    if (width >= height) {
      height = width;
      viewHeight = viewWidth;
    }
    // if portrait
    if (height >= viewHeight) {
      scale = height / (Math.round(viewHeight - margin));
    }
    // scale factor
    return 1 / scale;
  }

  getThreshold(): void {
    this.getActiveStyle('threshold', null);
  }

  setThreshold(value): void {
    this.setActiveStyle('threshold', value, null);
    // renderThreshold(value);
  }

  getInvert(): object {
    return this.props.invert;
  }

  setInvert(value): void {
    this.props.invert = value;
    this.applyFilterValue(2, 'invert', this.props.invert);
    this.applyFilterValue(2, 'engraveColor', this.props.fill);

    return this.props.invert;
  }

  isFilterable(): object {
    return this.getActiveStyle('filterable', null);
  }


  /**********************/
  /* HELPER FUNCTIONS */
  /*********************/

  confirmClear(): void {
    if (confirm('Wirklich nochmal neu anfangen?')) {
     this.canvas.clear();
    }
  }

  fileDropped(e): void {
    this.isUploadLoading = true;
    this.uploader.uploadAll();

    let self = this;
    // console.log(this.session.identifier)
    // setInterval(() => {
    setTimeout(() => {
      // console.log("hello in interval");
      self.updateUploadedImages();
      self.isUploadLoading = false;
    }, 3000);
    // backup for big images
    setTimeout(() => {
      // console.log("hello in interval");
      self.updateUploadedImages();
    }, 9000);


  }

  updateUploadedImages(): void {

    if(!this.session.identifier)
      return;

    this._signuuService.getUploadedImages(this.session.identifier).subscribe(
      // the first argument is a function which runs on success
      data => {
        console.log('get uploaded images')
        console.log(data)
        this.uploadedImages = data;
      },
      // the second argument is a function which runs on error
      err => console.log(err),
      // the third argument is a function which runs on completion
      () => console.log('done loading uploaded images')
    );
  }

  /*********************/
  /* FABRIC FUNCTIONS */
  /*******************/


  getRandomLeftTop(): object {
    let offset = 50;
    return {
        left: fabric.util.getRandomInt(0 + offset, 700 - offset),
        top: fabric.util.getRandomInt(0 + offset, 500 - offset)
    };
  }

  getRandomColor(): string {
    let self = this;
    let getRandomInt = fabric.util.getRandomInt;
    return (
        self.pad(getRandomInt(0, 255).toString(16), 2) +
        self.pad(getRandomInt(0, 255).toString(16), 2) +
        self.pad(getRandomInt(0, 255).toString(16), 2)
    );
  }

  ////
  // Export and import

  // show optimized SVG

  reviverSVG(markup): string {
    //console.log(markup);
    let mark = markup.replace(/fill: url\(#SVGID_.*\);/g, 'fill: none;').replace(/fill: rgb\(255,255,255\);/g, 'fill: none;').replace(/stroke-width: 0;/g, 'stroke-width: 0.05;');
    //console.log('--------------------------');
    //console.log(mark);
    return mark;
  }

  drawYellowLine(markup): string {
    let mark = markup.replace(/stroke: none;/g, 'stroke: rgb(255,255,0);').replace(/stroke-width: 1;/g, 'stroke-width: 0.1;');;
    return mark;
  }

  exportCanvas(format, session): void {
    console.log('exportCanvas')
    console.log(this)
    console.log(session)
    let self = this;
    console.log(self.session)
    // save Canvas State
    const canvasState = this.canvas.toJSON(['selectable']);

    switch (format) {
      // render SVG width black Objects & clipping
      case 'svg_show': {
        // color Canvas Objects black for engraving
        this.colorObjects(this.canvas, this.props, 'rgb(0,0,0)');
        const SVG = this.renderClippedSVG(this.canvas, this.activeProductView);
        document.getElementById('svg_container').innerHTML = SVG;
        break;
      }
      // render SVG width black Objects & clipping
      case 'svg_download': {
        // color Canvas Objects black for engraving
        this.colorObjects(this.canvas, this.props, 'rgb(0,0,0)');
        const SVG = this.renderClippedSVG(this.canvas, this.activeProductView);
        this.downloadVector(SVG);
        break;
      }
      // render previewImage
      case 'png': {
        this.createPreviewImage(this.canvas, this.activeProductView);
        break;
      }
      // render engraving PNG
      case 'forOnevent': {
        //let preview = this.createEngravingPNG(this.canvas, this.activeProductView);
        //this.session.preview = preview.src;
        //document.body.appendChild(this.session.preview);
        //self.session.svg = this.renderClippedSVG(this.canvas, this.activeProductView);
        this.createPreviewImage(this.canvas, this.activeProductView);
        //document.getElementById('svg_container').innerHTML = self.session.preview;

        console.log('#### before update in render engraving')
        console.log(self.session)
        console.log(this.session)
        console.log('####')

        this.updateSession(self.session);
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    // restore Canvas State
    this.canvas.loadFromJSON(canvasState, function() {
      // set clipPath (Engraving Area)
      self.createClipPath(self.canvas, self.activeProductView);
      self.colorObjects(self.canvas, self.props, self.props.fill);
      self.canvas.renderAll();
    });
  }

  colorObjects(canvas, props, desiredColor): void {

    /*if (props.fill === desiredColor) {
      return;
    }*/
    canvas.forEachObject(function(obj){
      if (!obj.selectable) {
        return;
      }
      if (!obj.filters) {
        obj.fill = desiredColor;
        return;
      }
      obj.filters.forEach( (filter) => {
        if (filter.type === 'BlendColor') {
          filter.color = desiredColor;
        }
        if (filter.type === 'EngraveReady' || 'EngraveInvert') {
          filter.engraveColor = desiredColor;
          obj.filterable = true;
        }
      });
      obj.applyFilters();
    });
  }

  renderClippedSVG(canvas, view): string {
    const svgOptions = {
      viewBox: {
        x: view.xPosition,
        y: view.yPosition,
        width: view.width + 2,
        height: view.height + 2
      },
      width: view.originalWidth + 'mm',
      height: view.originalHeight + 'mm'
    };
    return canvas.toClippedSVG(svgOptions);
  }

  downloadVector(svg): void {
    let blob = new Blob(
      [svg],
      {type: 'image/svg+xml;charset=utf-8'}
    );
    let url = window.URL || window.webkitURL;
    let clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false
    });
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url.createObjectURL(blob));
    downloadLink.setAttribute('download', this.sessionID + '.svg');
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.dispatchEvent(clickEvent);
    downloadLink.remove();
  }

  createPreviewImage(canvas, view): void {

    let self = this;
    let url = this.activeProductView.image;

    //document.getElementById('toDataURL')['src'] = self.canvas.toDataURL();
    let engraving = canvas.toDataURL();
    //this.debugImageOutput(engraving);
    // color Canvas Objects black for engraving
    this.colorObjects(this.canvas, this.props, 'rgb(0,0,0)');
    let svg = this.renderClippedSVG(canvas, view);
    let preview_canvas = new fabric.Canvas('preview_canvas', {width: 500, height: 500});

    fabric.Image.fromURL(engraving, function(img) {
      preview_canvas.add(img);
      preview_canvas.setBackgroundImage(url, function() {
        self.setSessionImages(preview_canvas.toDataURL(), svg);
      }, { crossOrigin: 'anonymous' });
    }, { crossOrigin: 'anonymous' });
  }

  setSessionImages(preview, svg): void {
    this.session.svg = svg;
    this.session.preview = preview;

    //this.debugImageOutput(this.session.preview);
    this.updateSession(this.session);
  }

  createEngravingPNG(canvas, view): any {

    let self = this;
    for (let i = 0; i < canvas.getObjects().length; i++) {
      let item = canvas.getObjects()[i];
      if (!item.selectable) {
        canvas.remove(item);
      }
    }
    self.colorObjects(canvas, self.props, 'rgb(0,0,0)');
    let image = new Image();
    image.src = canvas.toDataURL();
    //window.open(image.src, '_blank');
    return image;
  }

  // file download
  downloadInTab(): void {
    // console.log(this.canvas.toSVG());
    // window.open(
    //   'data:image/svg+xml;utf8,' +
    //   encodeURIComponent(this.canvas.toSVG()));
    // console.log(this.canvas.toSVG())
    // var image = new Image();
    // image.src = this.canvas.toSVG()
    var w = window.open("");
    w.document.write(this.canvas.toSVG());
  }

    saveJSON(): void {
        if(typeof this.session.preview === 'undefined')
            this.session.preview = {};
        this.session.preview = JSON.stringify(this.canvas);
        this.session.svg = this.canvas.toSVG();
        console.log(this.session);
        console.log(this.canvas);
        console.log(this.canvas.toSVG());
    }

    loadJSON(): void {
        this.canvas.loadFromJSON(this.session.preview, function (canvas) {
            canvas.renderAll();
        });
    }

    ////
    // tools
    pad(str, length): string {
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    }

    preloadFonts(): void {
        for (let font of this.fonts) {
          let span = document.createElement('span');
          span.innerHTML = 'abc123 ';
          span.setAttribute('style', 'font-family:' + font.name + ';');
          // span.style.display = "none";
          document.body.appendChild(span);
        }
    }

    reload(): void {
      window.location.hash = '';
      this.createSession();
    }

  debugImageOutput(src): void {
    let image = new Image();
    image.src = src;
    //window.open(image.src, '_blank');
    document.body.appendChild(image);
  }
}
