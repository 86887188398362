import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SignuuService {

    constructor(private http:HttpClient) {
    }

    public serviceUrl = 'https://v2api.signuu.com';
    public serviceV1Url = 'https://www.signuu.com/konfigurator/client/ServiceProxy.php';
    public serviceOneventUrl = 'https://www.signuu.com/konfigurator/onevent/ServiceProxy.php';
    public serviceV1ProductUrl = 'https://www.signuu.com/konfigurator/client/LoadProductV2.php';
    public serviceClientV2 = 'https://www.signuu.com/konfigurator/client/';

    getTimeStamp() {
      return '&_=' + new Date().getTime();
    }

    getOrganizations() {
        return this.http.get(this.serviceUrl + '/organizations');
    }

    getLaserProductId(id) {
      return this.http.get(this.serviceV1Url + '?url=productid/' + id);
    }

    // Uses Observable.forkJoin() to run multiple concurrent http.get() requests.
    // The entire operation will result in an error state if any single request fails.
    getProductsAndPictures(id) {
      return forkJoin(
        //this.http.get(this.serviceUrl + '/products/' +id),
        //this.http.get(this.serviceUrl + '/laser_products/' + id)
        this.http.get(this.serviceV1ProductUrl + '?pid=' + id + this.getTimeStamp()),
        // this.http.get(this.serviceV1Url + '/laser_products/' + id)
        );
    }

    getUploadedImages(id){
      return this.http.get(this.serviceClientV2 + '/BilderV2.php?session=' + id + this.getTimeStamp());
    }

    getProductPrice(id, config) {
      config = JSON.stringify(config);
      let options =  {headers: new  HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})};
      let body = 'url=produktpreis/id/'+id+'&config='+config;
      return this.http.post(this.serviceV1Url, body, options);
    }

    getSession(identifier) {
        return this.http.get(this.serviceUrl + '/sessions?identifier=' + identifier);
    }

    // send a POST request to the API to create a new data object
    createSession(session) {
        //console.log(session);
        //console.log(this.serviceUrl);
        let body = JSON.stringify(session);
        return this.http.post(this.serviceUrl + '/sessions', body, httpOptions);
    }

    // send a PUT request to the API to update a data object
    updateSession(session) {
        let body = JSON.stringify(session);
        return this.http.put(this.serviceUrl + '/sessions/' + session.id, body, httpOptions);
    }

    // send a DELETE request to the API to delete a data object
    deleteSession(session) {
        return this.http.delete(this.serviceUrl + '/sessions/' + session.id);
    }
    /*
    getMotives() {
      return this.http.get('https://signuu.com/konfigurator/service/motivliste');
    }
    */
}
